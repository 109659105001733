<template>
  <div class="main">
    <div class="allSubmit">
      <van-button type="primary" size="small" block @click="confirmSubmit"
        >签发</van-button
      >
      <van-button type="danger" size="small" block @click="cancelSubmit"
        >退回</van-button
      >
    </div>
    <div class="main_cont">
      <div class="checkBox">
        <div class="title">委托三方监火人</div>
        <van-cell-group>
          <van-checkbox-group v-model="ctfirewatch" direction="horizontal">
            <van-checkbox
              v-for="(items, index) in options.ct_fire_watch"
              :key="index"
              :name="items.value.toString()"
              shape="square"
              >{{ items.label }}</van-checkbox
            ><br />
          </van-checkbox-group>
        </van-cell-group>
      </div>
      <div class="checkBox">
        <div class="title">内部监火人</div>
        <van-cell-group>
          <van-checkbox-group v-model="companyfirewatch" direction="horizontal">
            <van-checkbox
              v-for="(items, index) in options.company_fire_watch"
              :key="index"
              :name="items.value.toString()"
              shape="square"
              >{{ items.label }}</van-checkbox
            >
          </van-checkbox-group>
        </van-cell-group>
      </div>
      <div class="checkBox">
        <div class="title">承包商监火人</div>
        <van-cell :value="supervisor_id"></van-cell>
      </div>
      <div class="checkBox">
        <div class="title">原因</div>
        <van-cell-group inset>
          <van-field
            v-model="job_msg"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入原因"
          />
        </van-cell-group>
      </div>
    </div>

    <!-- 签名 -->
    <handwritingHtml
      @writingCommit="writingCommit"
      ref="wirteDialog"
    ></handwritingHtml>
  </div>
</template>
<script>
/* eslint-disable */
import { reactive,onMounted,ref,getCurrentInstance} from 'vue';
import handwritingHtml from '../../../components/handwriting.vue';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import {Notify,Dialog} from 'vant';
export default {
    components:{handwritingHtml},
    setup() {
        const route = useRoute();
        const router = useRouter();
        const $this = getCurrentInstance();
       const supervisor_id = ref(route.params.supervisor_id!=0?route.params.supervisor_id:'无');
       const worklevel = ref(route.params.worklevel)
        const signForm = reactive({
            company_fire_watch:'',
            ct_fire_watch:''
        });
        const options = reactive({
            company_fire_watch:[],
            ct_fire_watch:[]
        });
        const getSelectList = () =>{
            Http.get({
                method:'listMultiPardata',
                t:'PTW_CETIFICATE',
                prop:'company_fire_watch,ct_fire_watch'
            },false,true)
            .then(res=>{
                options.company_fire_watch = res.company_fire_watch;
                options.ct_fire_watch = res.ct_fire_watch;
            })
            .catch(()=>{})
        }
        const companyfirewatch = ref(route.params.company_fire_watch == '0'?[]:route.params.company_fire_watch.split(','));
        const ctfirewatch = ref( route.params.ct_fire_watch== '0'?[]:route.params.ct_fire_watch.split(','));
        const writingCommit = (res)=>{
            var attachment3 = []
            attachment3.push({document_path:res,document_code:'sign'});
            Http.post({
                method:'saveorupdate',
                t:'PTW_CETIFICATE',
                action:'success',
                id:route.params.id,
                attachment3:attachment3,
                company_fire_watch:companyfirewatch.value.join(','),
                ct_fire_watch:ctfirewatch.value.join(',')
            },false,true)
            .then(()=>{
                router.push('/ptwDetail/' + route.params.id)
            })
            .catch(()=>{})
            // Dialog.confirm({
            //     title: '标题',
            //     message:'是否确认签发',
            // })
            // .then(() => {
                
            // })
            // .catch(() => {});
        }
        const confirmSubmit = () =>{
            if((route.params.typeId == 7 && (worklevel.value == 18 || worklevel.value == 19 )) || route.params.typeId == 8){
                if(companyfirewatch.value.length == 0 && ctfirewatch.value.length == 0){
                    Notify({ type: 'warning', message: '监护/火人 必须填写一个！',duration: 2000, });
                    return false;
                }
            }else{
                if(route.params.supervisor_id==0 && companyfirewatch.value.length == 0 && ctfirewatch.value.length == 0){
                    Notify({ type: 'warning', message: '监护/火人 必须填写一个！',duration: 2000, });
                    return false;
                }
            }
            
            $this.refs.wirteDialog.show()
        }
        const job_msg = ref('');
        const cancelSubmit = () =>{
            if(job_msg.value == ''){
                Notify({ type: 'warning', message: '退回原因必填！',duration: 2000, });
                return false;
            }
            Dialog.confirm({
                title: '标题',
                message:'是否确认退回',
            })
            .then(() => {
                Http.post({
                    method:'saveorupdate',
                    t:'PTW_CETIFICATE',
                    action:route.params.status == 7?'close':'fail',
                    id:route.params.id,
                    job_msg:job_msg.value
                },false,true)
                .then(()=>{
                    router.push('/ptwDetail/' + route.params.id)
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }
        onMounted(()=>{
            
            getSelectList();
        });
        return {
            signForm,
            companyfirewatch,
            ctfirewatch,
            options,
            cancelSubmit,
            confirmSubmit,
            writingCommit,
            job_msg,
            supervisor_id,
            worklevel
        }
    },
}
</script>
<style scoped lang='less'>
.allSubmit{
    height: 35px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'blod';
    font-size: 16px;
}
.main_cont{
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 10px 0;
    width: 100%;
}
.van-checkbox{
    margin-bottom: 10px;
}
.checkBox{
    width: 95%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    .title{
        padding:10px;
        text-indent: 5px;
        font-size: 15px;
        font-family: 'blod';
        border-bottom: 1px solid #eeeeee;
    }
    .van-cell-group{
        padding: 10px;
    }
    .button{
        width:100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
</style>